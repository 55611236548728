<template>
  <div>
    <router-link
      v-if="userInfo"
      :to="{
        path: `${rowFormat(row)[0]}`,
      }"
      >{{ rowFormat(row)[1] }}</router-link
    >
        <!-- query: tableParam, -->
    <div v-else>{{ rowFormat(row)[1] }}</div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  created() {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
    }
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    tableParam: {
      type: Object,
      default: () => {},
    },
    rowFormat: {
      type: Function,
      default: () => {},
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
// .route-box {
//   background: #000;
//   width: 100px;
//   height: 100px;
//   color: red;
// }
</style>
